import React, { FunctionComponent, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { connect } from "react-redux";
import { updatedProjectFBAllPage } from "../../Services/organizations";

type Props = {
  pageFormsData: any;
  pageId: any;
  user: any;
  allFacebookPages: any;
  dispatcher: any;
  projectsList: string[];
  setExpandedId:any;
};

const FormModel: FunctionComponent<Props> = ({
  pageFormsData,
  pageId,
  user,
  allFacebookPages,
  dispatcher,
  projectsList,
  setExpandedId
}) => {
  const [textValues, setTextValues] = useState<{ [key: string]: string }>(
    pageFormsData?.reduce((acc: any, form: any) => {
      acc[form.id] = form.project || "";
      return acc;
    }, {} as { [key: string]: string })
  );
  const handleChange = (id: string, value: string) => {
    setTextValues((prev) => ({
      ...prev,
      [id]: value || "",
    }));
  };
  const handleSave = () => {
    const updatedFormArray = pageFormsData?.map((item: any) => {
      if (textValues[item.id]) {
        return { ...item, project: textValues[item.id] || "" };
      } else if (textValues[item.id] === "") {
        return { ...item, project: "" };
      }
      return item;
    });
    updatedProjectFBAllPage(
      user.organization_id,
      dispatcher,
      allFacebookPages,
      updatedFormArray,
      pageId,
      setExpandedId
    );
  };

  return (
    <>
      <Box
        id="modal-description"
        sx={{
          mt: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          padding: 2,
          border: "1px solid #ddd",
          borderRadius: 2,
          backgroundColor: "#fff",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        {pageFormsData?.map((form: any, index: number) => (
          <Box
            key={form.id}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingLeft:2,
              paddingRight:2,
              paddingTop:1,
              paddingBottom:1,
              marginBottom: 1,
              border: "1px solid #ddd",
              borderRadius: 2,
              backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#fff",
            }}
          >
            <Typography
              sx={{
                flex: 1,
                fontFamily: "Poppins, sans-serif",
                fontSize: "14px",
                fontWeight: 500,
                color: "#333",
              }}
            >
              {form.name}
            </Typography>
            <Select
              sx={{
                flex: 1,
                ml: 2,
                backgroundColor: "#fff",
                borderRadius: 1,
                "& .MuiSelect-select": {
                  padding: "8px",
                },
              }}
              size="small"
              displayEmpty
              value={textValues[form.id] || ""}
              onChange={(e) => handleChange(form.id, e.target.value)}
            >
              <MenuItem value="">
                <em style={{ fontStyle: "initial" }}>Select a project</em>
              </MenuItem>
              {projectsList?.map((project: string) => (
                <MenuItem key={project} value={project}>
                  {project}
                </MenuItem>
              ))}
            </Select>
          </Box>
        ))}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: 3,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            sx={{
              paddingX: 4,
              paddingY: 1,
              fontSize: "14px",
              textTransform: "none",
              fontWeight: "bold",
              backgroundColor: "#272944",
              ":hover": {
                backgroundColor: "#1e2037",
              },
            }}
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    theme: state.theme.isLightMode,
  };
};

export default connect(mapStateToProps)(FormModel);
